@import '../_var.less';

@atom: Popup;

.PopupWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: @z-popup;
    transition: opacity 0.1s ease-in, z-index .01ms;

    &.PopupWrapper--hidden {
        opacity: 0;
        z-index: -1;
        transition: opacity 0.1s ease-in, z-index .15s .01ms;

        .@{atom} {
            transform: translateY(-40px);
        }
    }

    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.3);
        z-index: -1;
    }

    .@{atom} {
        @dim-modal-w: 400px;

        transition: transform 0.15s ease-in-out;
        min-width: @dim-modal-w;
        max-width: @dim-modal-w;
        padding-top: @dim-sm;
        cursor: default;
        box-shadow: 0 10px 30px -10px rgba(0,0,0,0.4);
        display: flex;
        flex-flow: column nowrap;
        background: @color-white;
        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        .@{atom}__image {
            margin: @dim-sm;

            img {
                width: 75px;
            }
        }

        .@{atom}__title {
            text-transform: inherit;
            font-size: @font-sm + 0.1em;
            margin: 0 0 @dim-sm 0;
        }

        .@{atom}__content {
            margin: 0 @dim-sm @dim-xs @dim-sm;
            overflow-y: auto;
            font-size: @font-sm - 0.15em;
            max-width: calc(100% - @dim-md);
        }

        .@{atom}__buttons {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin: 0 @dim-sm @dim-sm @dim-sm;
            width: calc(100% - @dim-sm*2);

            .Button {
                margin-bottom: 0;
            }

            .@{atom}__leftButtonsCtn {}

            .@{atom}__rightButtonsCtn {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                .Button:last-child {
                    margin-right: 0;
                }
            }
        }

        .@{atom}__moreInfo {
            font-size: .7em;
            color: @color-primary;
            width: 8rem;
            display: none;
            line-height: 1.3em;

            .Button--loading ~ & {
                display: inline-block;
            }
        }
    }
}